import React, { useRef, useEffect, useState } from 'react';
import './App.css';


export const Header = () => {
    const [toggle, setToggle] = useState(true);
    const handleToggle = () => {
        setToggle(!toggle)
    }

    const scrollToTop = () => {
        const elem = document.getElementById("scroll");
        elem.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            {toggle ?
                <div className='contact' onClick={() => handleToggle()}>
                    <div>Contact</div>
                </div>
                :
                <div onClick={() => handleToggle()} className='contactToggled'>
                    <div style={{ width: "100%", textAlign: "right" }}>Contact</div>
                    <div className='flex-container'>
                        <div>
                            <div style={{ width: "100%", marginTop: "20px", fontWeight: "bold", fontSize: "30px" }}>Tom Laszlo</div>
                            <div style={{ width: "100%", marginTop: "20px", fontSize: "16px" }}>tom@tomlaszlo.com</div>
                            <div style={{ width: "100%", marginTop: "10px", fontSize: "16px" }}>+36-30-352-4001</div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            }

            <div onClick={() => scrollToTop()} style={{ position: "fixed", top: "20px", left: "10px" }}>
                <img style={{ width: "75px", borderRadius: "50%", fontSize: "20px" }} src="profile.jpg" />
            </div>
        </>
    )
}
export const HeaderM = () => {
    const [toggle, setToggle] = useState(true);
    const handleToggle = () => {
        setToggle(!toggle)
    }

    const scrollToTop = () => {
        const elem = document.getElementById("scroll");
        elem.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            {toggle ?
                <div className='contact' onClick={() => handleToggle()}>
                    <div>Contact</div>
                </div>
                :
                <div onClick={() => handleToggle()} className='contactToggled'>
                    <div style={{ width: "100%", textAlign: "right" }}>Contact</div>
                    <div className='flex-container'>
                        <div>
                            <div style={{ width: "100%", marginTop: "20px", fontWeight: "bold", fontSize: "30px" }}>Tom Laszlo</div>
                            <div style={{ width: "100%", marginTop: "20px", fontSize: "16px" }}>tom@tomlaszlo.com</div>
                            <div style={{ width: "100%", marginTop: "10px", fontSize: "16px" }}>+36-30-352-4001</div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            }

            <div onClick={() => scrollToTop()} style={{ position: "fixed", top: "20px", left: "10px" }}>
                <img style={{ width: "75px", borderRadius: "50%", fontSize: "20px" }} src="profile.jpg" />
            </div>
        </>
    )
}
