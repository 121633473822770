import VideoScroller from './video_scroll.js'
import VideoScrollerM from './video_scroll_mobile.js'
import { Header } from './header.js'
import './App.css'

import React, { useEffect, useRef, useState } from "react";

function App() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <>
      {windowSize < 600 ?
        <>
          <VideoScrollerM />
          <Header />
        </>
        :
        <>
          <VideoScroller />
          <Header />
        </>
      }
    </>
  );
}

export default App;
