import React, { useRef, useEffect, useState } from 'react';

const VideoScrollerM = () => {
    const scrolliconRef = useRef(null);
    const imageRef = useRef(null)
    const [images, setimages] = useState([]);
    const [preload, setPreload] = useState(1)
    const [frameIndex, setFrameIndex] = useState(0)

    function zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    const drawImage = frameIndex => {
        imageRef.current.src = images[frameIndex].src
    }

    const onScroll = e => {
        const frameCount = 601
        const scrollTop = e.target.scrollTop
        const maxScrollTop = e.target.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.max(0, Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount)))
        //setFrameIndex(frameIndex)
        drawImage(frameIndex)
        requestAnimationFrame(() => drawImage(frameIndex))
        if (frameIndex > 1) {
            scrolliconRef.current.style.display = "none"
        }
        if (frameIndex == 0) {
            scrolliconRef.current.style.display = "block"
        }
    };

    useEffect(() => {
        let image = new Image()
        image.src = `./images/tomlaszlo_v5_mobile_000.jpg`
        image.onload = () => {
            imageRef.current.src = image.src
        };
    }, []);

    useEffect(() => {
        const frameCount = 601
        const imgs = []
        for (var i = 0; i < frameCount; i++) {
            const image = new Image()
            image.src = `./images/tomlaszlo_v5_mobile_${zeroPad(i, 3)}.jpg`

            if (i == 300) {
                image.onload = () => { setPreload(0) }
            }
            imgs.push(image)
        }
        setimages(imgs)
    }, []);

    return (
        <>
            <div style={{ position: 'fixed', height: '100%', width: '100%' }} >
                <div style={{ position: "fixed", display: "flex", height: "100%", alignItems: "center" }}>
                    <img ref={imageRef} src="./images/tomlaszlo_v5_mobile_000.jpg" width="100%" />
                </div>
                {preload == 0 ?
                    <>
                        <div style={{ position: "absolute", bottom: "20px", marginLeft: "10px", width: "100%" }}>
                            <div>
                                <a target="_blank" href="https://www.worldphoto.org/sony-world-photography-awards/winners-galleries/2024/open/winners/object"><img src="swpa24.png" width="80px" /></a>&nbsp;
                                <a target="_blank" href="https://photoawards.com/winner/zoom.php?eid=8-1686638099-23"><img src="create_hmention_seal22-1.png" width="90px" /></a>
                                <div ref={scrolliconRef} style={{ position: "absolute", bottom: "60px" }}>
                                    <dotlottie-player src="swipe_small.json" background="transparent" speed="1" direction="1" playMode="normal" loop autoplay></dotlottie-player>
                                </div>
                                <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                    <a href="https://www.instagram.com/tom.laszlo/" target="_blank">
                                        <img src="./instagram.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="scroll" onScroll={(e) => onScroll(e)} style={{ position: "absolute", top: "0", left:"0",right:"0",overflowY: "scroll", bottom: "80px" }}>
                            <div style={{ position: "absolute", height: "2000vh", width: "100vw" }}>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignContent: "center", justifyContent: "center", background: "rgba(0, 0, 0, 0.6)" }}>
                            <dotlottie-player src="spinner.json" background="transparent" speed="1" style={{ width: "200px" }} direction="1" playMode="normal" loop autoplay></dotlottie-player>
                        </div>
                        <div style={{ zIndex: "2", position: "absolute", bottom: "20px", marginLeft: "10px", width: "100%" }}>
                            <a target="_blank" href="https://www.worldphoto.org/sony-world-photography-awards/winners-galleries/2024/open/winners/object"><img src="swpa24.png" width="80px" /></a>&nbsp;
                            <a target="_blank" href="https://photoawards.com/winner/zoom.php?eid=8-1686638099-23"><img src="create_hmention_seal22-1.png" width="90px" /></a>
                        </div>
                        <div style={{ zIndex: "2", position: "absolute", bottom: "44px", right: "10px" }}>
                            <a href="https://www.instagram.com/tom.laszlo/" target="_blank">
                                <img src="./instagram.svg" />
                            </a>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default VideoScrollerM;